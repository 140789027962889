<template>
  <article class="PlayerStreaming">
    <div class="content">
      <div class="name font-color--primary font-style--readable">
        {{player.name}}
      </div>
      <div class="life font-color--primary font-style--readable">
        {{player.life}}
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Player',
  components: {
  },
  props: ['player', 'life', 'game'],
  computed: {
  },
  methods: {

    hexToRGB: function(h) {
      let r = 0, g = 0, b = 0;

      // 3 digits
      if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

      // 6 digits
      } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
      }
      
      return "rgb("+ +r + "," + +g + "," + +b + ", 0.75)";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.PlayerStreaming {
  flex: 50%;
  background: @app-color-primary-1;
  padding: 25px;
  
  box-sizing: border-box;
  .content {
    padding: 5px;
    font-weight: bolder;
    background: green;

    .life {
      font-size: clamp(0vw, 8vw, 80px);
    }

    .name {
      font-size: clamp(0vw, 3vw, 40px);
    }
  }
}
</style>