<template>
  <div class="Life">

    <section class="players"  v-if="(!this.$store.state.player.settings.streamingMode && $store.state.player.isObserver) || !$store.state.player.isObserver">
      <Player
        v-for="player in opponentMap" :key="player.publicId"
        :player="player"
        :game="$store.state.game.game_obj"
        :life="player.life">
      </Player>
      <PlayerPlaceholder v-if="false && emptyOpponentCount > 0"></PlayerPlaceholder>
    </section>

    <!-- Streaming mode -->
    <section class="players--streaming"  v-if="$store.state.player.isObserver && this.$store.state.player.settings.streamingMode">
      <PlayerStreaming
        v-for="player in opponentMap" :key="player.publicId"
        :player="player"
        :game="$store.state.game.game_obj"
        :life="player.life">
      </PlayerStreaming>
      <Tile z="1">
        <h2>Streaming mode</h2>
        <section class="font-color--primary">
          Streaming mode is active! You can add chroma keys for the purple board (#8A2BE2) and the green life boxes (#008000)
        </section>
      </Tile>
    </section>

    <!-- Tools Drawer -->
    <Drawer
      title="Random Tools"
      v-if="!$store.state.player.isObserver"
      :shouldOpen.sync="$store.state.player.settings.showTools"
      :closeCallback="closeDrawerCallback">
      <Tools ref="tools"></Tools>
    </Drawer>

    <!-- Placeholder for empty players -->
    <div class="emptyPage" v-if="Object.keys(opponentMap).length === 0">
      <div class="empty--content">
        <font-awesome-icon :icon="['fa', 'users']" />
        <div class="text">Other players will appear here when they join your game</div>
      </div>
    </div>

    <LifeToggle
      v-if="isPlayer"
      :player="$store.state.player">
    </LifeToggle>
  </div>
</template>
<script>
  import Drawer from "@/components/Drawer.vue";
  import Player from "@/components/Player.vue";
  import PlayerStreaming from "@/components/PlayerStreaming.vue";
  import PlayerPlaceholder from "@/components/PlayerPlaceholder.vue";
  import LifeToggle from "@/components/LifeToggle.vue";
  import Tile from "@/components/Tile.vue";
  import Tools from "@/views/partials/Tools.vue";
  export default {
    name: 'Life',
    components: {
      Drawer,
      Player,
      PlayerStreaming,
      PlayerPlaceholder,
      LifeToggle,
      Tile,
      Tools
    },
    computed: {
      opponentMap: function() {
        let ret = Object.assign({}, this.$store.state.game.game_obj.players);
        delete ret[this.$store.state.player.publicId];
        return Object.values(ret);
      },
      emptyOpponentCount: function() {
        let ret = Object.assign({}, this.$store.state.game.game_obj.players);
        delete ret[this.$store.state.player.publicId];
        console.log(Object.values(ret));
        return 3 - Object.values(ret).length;
      },
      isPlayer: function() {
        return this.$store.state.game.game_obj.players[this.$store.state.player.publicId];
      }
    },
    data() {
      return {
        mode:"life",
        shouldOpenDrawer: false,
      }
    },
    methods: {
      openDrawer() {
      },
      closeDrawerCallback() {
        console.log("goodbye");
        setTimeout(() => {
          this.$refs.tools.clearValues();
        }, 500);
        this.$store.commit('set_player_settings', {showTools: false});
      }
    }
  }
</script>

<style scoped lang="less">
  .Life {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .players {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 120px;

      &--streaming {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100vw;
        box-sizing: border-box;
        
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-height: 500px;

        max-width: 1000px;

        .Tile {
          margin-top: 50px;
        }
      }
    }
  }

  .LifeToggle {
    position: fixed;
    bottom: 100px;
    margin-right: 50px;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

  .tickerRow {
    background: rgba(31, 31, 31, 0.95);
    width: 100%;
    max-width: 600px;
    height: 100px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    * {
      box-sizing: border-box;
    }

    .life--container {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      width: 110px;
      height: 110px;
      border: 5px solid black;
      border-radius: 50%;

      .life {
        
        
        font-weight: bold;
        position: relative;
        
      }
    }

    button {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      background: rgba(0,0,0,0);
      border: none;
      outline: none;
    }

    & > * {
      font-size: 3rem;
      margin: 5px;
    }


  }

  .modeRow {
    display: none;
  }


</style>