<template>
  <section class="Tools">
    <div class="randomResult">
      <div id="tempResult">
        <div v-if="this.rollType === 'players'">
          <ProfilePic size="large" :icon="this.finalResult.avatar"></ProfilePic>
        </div>
        <span v-if="this.rollType === 'd2'">
          <font-awesome-icon class='icon' :icon="['fas', this.finalResult === 'Heads' ? 'skull' : 'dragon']" />
        </span>
        <span v-if="this.rollType !== 'd2' && this.rollType !== 'players'">
          {{this.finalResult}}
        </span>
      </div>
      <label for="tempResult">
        <div v-bind:class="{ready : !this.isRandomizing}">
          <span v-if="this.rollType === 'd2'">
            {{this.finalResult !== '?' ? this.finalResult : ''}}
          </span>
          <span v-if="this.rollType === 'd20' || this.rollType === 'd6'">
            {{this.finalResult !== '?' ? this.formatNumberResult(this.finalResult) : ''}}
          </span>
          <span v-if="this.rollType === 'players'">
            {{this.finalResult.name}}
          </span>
        </div>
      </label>
    </div>
    <div class="toolSection">
      <div class="tool">
        <button id="coin" class="z-index--1 toolIcon" @click="getRandomNum('d2')">
          <font-awesome-icon class='icon' :icon="['far', 'coin']" />
        </button>
        <label for="coin">
          Flip coin
        </label>
      </div>
      <div class="tool">
        <button id="d6" class="z-index--1 toolIcon" @click="getRandomNum('d6')">
          <font-awesome-icon class='icon' :icon="['far', 'dice-d6']" />
        </button>
        <label for="d6">
          Roll d6
        </label>
      </div>
      <div class="tool">
        <button id="d20" class="z-index--1 toolIcon" @click="getRandomNum('d20')">
          <font-awesome-icon class='icon' :icon="['far', 'dice-d20']" />
        </button>
        <label for="d20">
          Roll d20
        </label>
      </div>
      <div class="tool" v-if="Object.keys($store.state.game.game_obj.players).length > 1">
        <button id="randomUser" class="z-index--1 toolIcon" @click="getRandomNum('players')">
          <font-awesome-icon class='icon' :icon="['far', 'users']" />
        </button>
        <label for="randomUser">
          Pick Player
        </label>
      </div>
    </div>
  </section>

</template>

<script>
  import ProfilePic from "@/components/ProfilePic.vue";
  export default {
    name: 'Tools',
    components: {
      ProfilePic
    },
    mounted() {
    },
    data() {
      return {
        isRandomizing: false,
        randomResult: '',
        finalResult: '?',
        rollType: '',
      }
    },
    computed: {

    },
    methods: {
      clearValues() {
        this.isRandomizing = false;
        this.randomResult = '';
        this.finalResult = '?';
        this.rollType = '';
      },
      getRandomNum(rollType, remainingTries=5) {
        let sides = 0;
        let icon = '';
        this.rollType = rollType;
        this.isRandomizing = true;

        switch(rollType) {
          case 'd2':
            sides = 2;
            icon = 'coin';
            break;
          case 'd6':
            sides = 6;
            icon = 'dice-d6';
            break;
          case 'd20':
            sides = 20;
            icon = 'dice-d20';
            break;
          case 'players':
            sides = Object.keys(this.$store.state.game.game_obj.players).length;
            icon = 'users';
            break;
        }

        const randomNum = Math.floor(Math.random() * sides);

        // Alternate results randomly.
        if(randomNum === this.randomResult) {
          return this.getRandomNum(rollType, remainingTries);
        }      

        // The final result.
        if(remainingTries === 0) {
          this.finalResult = this.formatRandomResult(rollType, randomNum);
          this.randomResult = '';
          this.isRandomizing = false;
          let emitValue = this.finalResult;
          if(rollType === 'players') {
            emitValue = this.finalResult.publicId;
          }
          this.$store.commit('roll_random', {
            'rollType': rollType,
            'rollResult': emitValue,
            'rollIcon': icon
          });
        } else {
          this.randomResult = randomNum;
          this.finalResult = this.formatRandomResult(rollType, randomNum);
          setTimeout(() => {this.getRandomNum(rollType, remainingTries-1)}, 150);
        }
      },

      formatRandomResult(rollType, num) {
        if(rollType === 'd2') {
          return num ? 'Heads' : 'Tails';
        } else if(rollType === 'd6' || rollType === 'd20') {
          return num + 1;
        } else if(rollType === 'players') {
          return this.$store.state.game.game_obj.players[Object.keys(this.$store.state.game.game_obj.players)[num]];
        } else {
          return num + 1;
        }
      },

      formatNumberResult(number) {
        const numObj = {
          '1': 'One',
          '2': 'Two',
          '3': 'Three',
          '4': 'Four',
          '5': 'Five',
          '6': 'Six',
          '7': 'Seven',
          '8': 'Eight',
          '9': 'Nine',
          '10': 'Ten',
          '11': 'Eleven',
          '12': 'Twelve',
          '13': 'Thirteen',
          '14': 'Fourteen',
          '15': 'Fifteen',
          '16': 'Sixteen',
          '17': 'Seventeen',
          '18': 'Eighteen',
          '19': 'Nineteen',
          '20': 'Twenty',
        }

        return numObj[number];
      }
    }
  }
</script>

<style scoped lang="less">
.Tools {
  .randomResult {
    font-size: 2em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    min-height: 2em;

    #tempResult {
      height: 80px;
      width: 80px;
      border-radius: 80px;
      background: #111;
      border: 3px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    label {
      font-size: .5em;
      min-height: 25px;

      div {
        display: block;
        
        transform: translateY(5px);
        opacity: 0.0;

        &.ready {
          transition: transform @animation-duration--normal, opacity @animation-duration--normal;
          transform: translateY(0px);
          opacity: 1;
        }
      }
      
    }
  }

  .toolSection {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    .tool {
      label {
        font-size: .75em;
      }

      button {
        transition: transform @animation-duration--fast, box-shadow @animation-duration--fast;
        &:active {
          transform:translateY(2px);
          box-shadow: 0px 1px 10px black;
        }
      }
    }

    .toolIcon {
      padding: 10px;
      margin: 10px 10px 5px 10px;
      border-radius: 50px;
      height: 50px;
      width: 50px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
      cursor: pointer;
      border: none;

      background: @app-color-primary;
      background: @app-color-conic;

      &.selected {
        background: @app-color-primary;
        color: @font-color-primary;
      }

      svg {
        font-size: 25px;
      }
    }  
  }
}
</style>