<template>
  <Tile z="1" class="LifeToggle corners--rounded">
    
    <div class="player--profile">
      <ProfilePic :icon="player.avatar"></ProfilePic>
      <span class="userName"></span>
    </div>

    <div class="tools">

      <ButtonBadge v-if="false" icon="tools" :callback="() => {toggleTools(true)}"></ButtonBadge>
      <div class="buttons">
        <div class="icon portrait--small">
          <font-awesome-icon v-if="player.counters[`Commander_Tax_${currentCommander}`].value <= 0" :icon="['far', 'helmet-battle']" />
          <div v-if="player.counters[`Commander_Tax_${currentCommander}`].value > 0" class="font-style--readable">
            {{player.counters[`Commander_Tax_${currentCommander}`].value}}
          </div>
        </div>
        <button class="counter counter--up" @click="changeCommanderRecast(1)"><font-awesome-icon :icon="['fas', 'triangle']" /></button>
        <button class="counter counter--down" @click="changeCommanderRecast(-1)"><font-awesome-icon :icon="['fas', 'triangle']" /></button>
      </div>
    </div>

    <div class="toggleSection">
      <div class="toggle" @click="updateLife(-1)">
        <img class="arrow arrow--down" src="@/../assets/Arrow2.svg" height="60px"/>
      </div>
      <div class="sphere z-index--1">
        
        <div class="sphere--contents">
          <svg id="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
              <use xlink:href="#gentle-wave" x="48" y="0" :fill="getFill('0.7')" />
              <use xlink:href="#gentle-wave" x="48" y="3" :fill="getFill('0.5')" />
              <use xlink:href="#gentle-wave" x="48" y="5" :fill="getFill('0.3')" />
              <use xlink:href="#gentle-wave" x="48" y="7" :fill="getFill('1')" />
            </g>
          </svg>
          <!-- The border that clips the fill -->
          <div class="rim">
          </div>
        </div>
        <div id="lifeArea">
          <span class="lifeDelta">3</span>
          <span id="lifeTotal">{{player.life}}</span>
          <span class="lifeDelta font-style--readable">
            {{lifeDeltaSign}}
            {{lifeDeltaAbs}}
          </span>
        </div>
      </div>
      <div class="toggle" @click="updateLife(1)">
        <img class="arrow arrow--up" src="@/../assets/Arrow2.svg" height="60px"/>
      </div>
    </div>

    <div class="names">
      <div class="playerName">
        {{player.name}}
      </div>
      <div class="commanderName" v-if="player.commanderList[currentCommander].name.trim() !== 'Commander'">
        {{player.commanderList[currentCommander].name}}
      </div>
    </div>

    <div class="commanderModeButton" v-if="player.commanderList.length > 1" v-bind:class="{inverse: currentCommander===1}">
      <button class="z-index--1" @click="toggleCommander()">
        <font-awesome-icon :icon="['fas', 'user-friends']" />
      </button>
    </div>

    <img
      v-if="currentCommander === 0 && player.commanderList && player.commanderList[0] && player.commanderList[0].image_uri"
      class="commanderPic"
      :src="player.commanderList[0].image_uri"/>

    <img
      v-if="currentCommander === 1 && player.commanderList && player.commanderList[1] && player.commanderList[1].image_uri"
      class="commanderPic"
      :src="player.commanderList[1].image_uri"/>
  </Tile>
</template>

<script>
import Tile from "@/components/Tile.vue";
import ButtonBadge from "@/components/ButtonBadge.vue";
import ProfilePic from "@/components/ProfilePic.vue";

export default {
  name: 'LifeToggle',
  props: {
    player: {}
  },
  components: {
    Tile,
    ButtonBadge,
    ProfilePic
  },
  data: () => {
    return {
      currentCommander: 0,
    }
  },
  beforeDestroy() {
    this.toggleTools(false);
  },
  computed: {
    lifeDelta: function() {
      if(this.player.life - this.player.pendingOldState.life) {
        return this.player.life - this.player.pendingOldState.life;
      } 
      else {
        return '';
      }
    },

    lifeDeltaAbs: function() {
      if(Math.abs(this.lifeDelta)) {
        return Math.abs(this.lifeDelta);
      } else {
        return '';
      }
    },

    lifeDeltaSign: function() {
      if(this.lifeDelta > 0) {
        return '+';
      } else if(this.lifeDelta < 0) {
        return '−';
      } else {
        return '';
      }
    }
  },
  methods: {
    updateLife(lifeDelta) {
      this.$store.commit('change_life', {"lifeDelta": lifeDelta});
    },

    async changeCommanderRecast(delta) {
      this.$store.commit('change_counter', {counterName:`Commander_Tax_${this.currentCommander}`, counterDelta:delta, counterIcon: 'helmet-battle'});
    },

    getFill(opacity) {
      return `rgba(${document.documentElement.style.getPropertyValue('--app-color--primary__rgb')}, ${opacity})`;
    },

    toggleTools(showTools) {
      console.log('time to show', showTools);
      this.$store.commit('set_player_settings', {showTools});
    },

    toggleCommander: function() {
      if(this.currentCommander === 0) {
        this.currentCommander = 1;
      } else {
        this.currentCommander = 0;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.LifeToggle {
  display: flex;
  flex-flow: row;
  width: ~"calc(100vw - 40px)" !important;
  max-width: 600px;

  box-sizing:border-box;
  position: relative;
  padding: 10px;

  display: flex;
  align-items: center;
  flex-flow: row;
  font-size: 32px;
  height: 100px;

  color: white;

  .arrow {
    
    position: relative;
    cursor: pointer;
    

    &--up {
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, .7));
      right: 30px;

      &:active {
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, .7)) brightness(200%);
      }
    }

    &--down {
      filter: drop-shadow(-5px -5px 5px rgba(0, 0, 0, .7));
      transform: rotate(180deg);
      left: 30px;

      &:active {
        filter: drop-shadow(-5px -5px 5px rgba(0, 0, 0, .7)) brightness(200%);
      }
    }
  }

  .player--profile {
    display: flex;
    flex-flow: column;
    position: absolute;

    .ProfilePic {
      position: relative;
      justify-content: center;
      align-items: center;
    }

    .userName {
      position: absolute;
      font-size: .4em;
      width: 50px;
      top: 75px;
    }
  }

  .toggleSection {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    .toggle {
      display: flex;
      font-size: 1.5em;
      height: 100px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 10px;
      

      z-index: 2;
    }
  }

  .tools {
    position: absolute;
    right: 15px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .names {
    position: absolute;
    bottom: 5px;
    font-size: .3em;
    width: 100%;
    display: flex;
    flex-flow: row;
    z-index: 2;

    * {
      width: 50%;
    }
    text-align: left;

    .commanderName {
      padding-left: 50px;
    }
  }

  .commanderModeButton {
    position: absolute;
    left: 60px;
    top: 5px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    &.inverse {

      button {
        background: white;
        color:@app-color-primary-1;

      }
      
    }

    * {
      cursor: pointer;
    }
    
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: @app-color-primary-1;
      border: 0;
      padding: 10px;
      height: 25px;
      width: 25px;
      border-radius: 25px;
      transition: background @animation-duration--fast, color @animation-duration--fast;
      font-size: .35em;
    }
  }

    .buttons {
      display: flex;
      flex-flow: column;

      .icon {
        color: white;
        background: black;
        font-size: .3em;
        position: absolute;
        top: 20px;
        left: 10px;
        
        height: 20px;
        width: 20px;
        z-index: 2;
      }

      .counter {
        width: 40px;
        height: 30px;
        border: none;
        background: @app-color-primary;
        color: white;
        outline: none;
        cursor: pointer;
        box-shadow:0px 5px 10px black;
        font-size: .3em;



        .corners--rounded();

        svg {
          padding-bottom: 5px;
        }

        &--down {
          margin-top: 2px;
          svg {
            transform: rotate(180deg);
          }
        }

        &:active {
          box-shadow: inset 0px 0px 3px 0px #000000;
          opacity: .8;
        }
      }
    }


  .commanderPic {
    position: absolute;
    right: 0;

    width: 200px;
    height: 100%;
    max-width: 75%;
    
    object-fit: cover;
    object-position: 100% 10%;
    mask-image: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1) 35%);

    border-radius: 0 @corners--rounded @corners--rounded 0;
    z-index: 1;

    @media @phone-down {
      max-width: 200px;
      object-position: 100% 10%;
      mask-image: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1) 35%);
    }
  }


  .sphere {
    @sphere--size: 80px;
    @sphere-rim--size: 2px;
    height: @sphere--size;
    min-width: @sphere--size;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    flex-flow: column;

    position: relative;
    background: linear-gradient(0deg, rgba(var(--app-color--primary__rgb),1) 40%, rgba(10,10,10,1) 50%);
    border-radius: 50%;
    border: @sphere-rim--size solid #ccc;
    z-index: 3;
    backdrop-filter: blur(5px);

    #lifeArea {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      #lifeTotal {
        z-index: 4;
        color: white;
        height: 40px;
        display: inline-flex;
        line-height: 35px;
      }

      .lifeDelta {
        &:first-of-type {
          opacity: 0;
        }
        height: 20px;
        margin: auto;
        z-index: 4;
        font-size: .5em;
        color: white;
        line-height: 10px;
      }

    }



    &--contents {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      
    }

    .rim {

      position: absolute;
      height: @sphere--size;
      width: @sphere--size;
      border-radius: 50%;
      border: @sphere-rim--size solid #ccc;
      top: -@sphere-rim--size;
      left: -@sphere-rim--size;
      box-sizing: border-box;
      z-index: 2;
    }

    #waves {
      height: 20px;
      margin-top: ~"calc(50px - 20px)";
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;

      .parallax > use {
        animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
      }
      .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
      }
      .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
      }
      .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
      }
      .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
      }
      @keyframes move-forever {
        0% {
         transform: translateX(-90px);
        }
        100% { 
          transform: translateX(85px);
        }
      }      
    }
  }



}
</style>