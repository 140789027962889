<template>
  <div class="PlayerPlaceholder">
    <div class="column">
      <div class="profile">
      </div>
      <div class="life font-style--readable font-color--primary">
        40
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerPlaceholder',
  props: {

  },
  data: () => {
    return {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.PlayerPlaceholder {
  width: 100%;
  height: 100px;
  padding: 15px 10px 10px 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  position: relative;
  border-radius: @corners--rounded;
  display: flex;
  flex-flow: row;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='10' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  .column {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    .profile {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      background: gray;
      opacity: .2;
    }

    .life {
      margin-top: 5px;
      font-size: 1em;
      font-weight: bold;
    }      
  }
}
</style>
