<template>
  <div class="ButtonBadge">
    <button :disabled="!isEnabled" class="z-index--1" type="button" @click="click()">
      <font-awesome-icon  v-if="!processing" :icon="['fas', icon]"/>
      <font-awesome-icon  v-if="processing" class="fa-spin" :icon="['fas', 'spinner']"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    processing: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    },
    icon: {
      type: String
    }
  },
  data: () => {
    return {

    }
  },
  computed: {
    isEnabled: function() {
      return this.enabled && !this.processing;
    },
  },
  methods: {
    click: function() {
      if(this.callback && this.isEnabled) {
        this.callback();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.ButtonBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: initial;
  
  button {
    cursor: pointer;
    
    transition: box-shadow .2s, margin-top .2s;
    border: 0;

    color: white;
    background: @app-color-primary;
    background: @app-color-conic;
    border: 0;
    
    height: 35px;
    width: 35px;
    border-radius: 25px;
    
    box-sizing: border-box;

    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    /* Disables selecting text on buttons */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:disabled {
      opacity: .5;
      cursor: initial;
    }

    .far {
      color: white;
      font-size: 32px;
    }

    &.style {
      &--secondary {
        color: @app-color-primary-1;
        background: @background-color-primary;
        box-shadow: none;
        opacity: .8;


        &:hover {
          box-shadow: none;
        }
      }
    }
  }
  
}
</style>